import { navigate } from "gatsby"
import React from "react"
import { Button, Container, Row } from "react-bootstrap"
import TitleLine from "../../components/utils/TitleLine"
import login from "../../scripts/login"
import "../../styles/SignUpPage.css"
import NewAsyncButton from "../../components/utils/NewAsyncButton.js"
import Loader from "react-spinners/GridLoader"
import { auth } from "../../firebase"

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firebase: null,
      authenticated: null,
      buttonText: "Resend Email",
      buttonColor: "outline-primary",
      emailVerified: false,
      description: "",
      initialized: false,
      user: null,
    }
    this.sendResetEmail = this.sendResetEmail.bind(this)
    this.init = this.init.bind(this)
    this.refresh = this.refresh.bind(this)
  }

  init() {
    if (this.state.authenticated !== null) {
      this.setState({ initialized: true })
      let user = auth.currentUser
      if (user) {
        this.setState({ user: user })
        user
          .sendEmailVerification({
            url: "https://remoteta.org" + this.props.navigate,
          })
          .then(() => {
            // logout()
          })
          .catch(error => {
            console.log(error)
            //TODO ERROR HANDLE
          })
      } else {
        login(this.props.email, this.props.password).then(user => {
          this.setState({ user: user })
          user
            .sendEmailVerification({
              url: "https://remoteta.org" + this.props.navigate,
            })
            .then(() => {
              // logout()
            })
            .catch(error => {
              console.log(error)
              //TODO ERROR HANDLE
            })
        })
      }
    }
  }
  sendResetEmail(reset) {
    let currentUser = auth.currentUser
    if (currentUser) {
      currentUser
        .sendEmailVerification({
          url: "https://remoteta.org" + this.props.navigate,
        })
        .then(() => {
          reset("Email Sent!")
        })
        .catch(error => {
          console.log(error)
          reset("An error occured. Try again?")
        })
    } else {
      login(this.props.email, this.props.password).then(user => {
        user
          .sendEmailVerification({
            url: "https://remoteta.org" + this.props.navigate,
          })
          .then(() => {
            reset("Email Sent!")
          })
          .catch(error => {
            console.log(error)
            reset("An error occured. Try again?")
          })
      })
    }
  }

  componentDidMount() {
    this.init()
    auth.onAuthStateChanged(user => {
      if (!user) {
        this.setState({ authenticated: false })
      } else {
        this.setState({ authenticated: true, user: user })
      }
    })
  }

  refresh(reset) {
    if (auth.currentUser) {
      auth.currentUser
        .reload()
        .then(() => {
          let user = auth.currentUser
          if (user) {
            if (user.emailVerified) {
              navigate(this.props.navigate)
            } else {
              reset("An error occured. Try again?")
            }
          } else {
            reset("An error occured. Try again?")
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      login(this.props.email, this.props.password)
        .then(user => {
          if (user) {
            if (user.emailVerified) {
              navigate(this.props.navigate)
            } else {
              reset("An error occured. Try again?")
            }
          } else {
            reset("An error occured. Try again?")
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  componentWillUnmount() {
    // let user = this.state.firebase.auth().currentUser
    // if (user && (!this.state.emailVerified || !user.emailVerified)) {
    //   logout()
    //     .then(() => {})
    //     .catch(error => {
    //       console.log(error)
    //     })
    // }
  }

  render() {
    if (!this.state.initialized) {
      this.init()
      return <Loader />
    } else {
      let email =
        this.props.email && this.props.email && this.props.email !== undefined
          ? this.props.email
          : auth.currentUser.email
      let description =
        "A verification email has been sent to " +
        email +
        ". Click the link in the email to verify your account. Follow the instructions on that page to finish setting up your account."
      if (!this.state.emailVerified) {
        return (
          <Container className="topSpacing justify-content-center">
            <TitleLine
              title="Verify Email"
              className="justify-content-center"
              description={description}
            />

            <Row
              className="justify-content-center"
              style={{ marginTop: "50px" }}
            >
              {/* <NewAsyncButton
                buttonVariants={["primary", "primary", "danger", "dashed"]}
                buttonTexts={[
                  "Resend Email",
                  "Resending...",
                  "Error Resending. Try Again?",
                  "Resent! Check your inbox & spam",
                ]}
                buttonColor={["blue", "blue", "red", "green"]}
                callback={this.sendResetEmail}
              />
              <div style={{ marginLeft: "20px", marginRight: "20px" }}></div>
              <NewAsyncButton
                buttonVariants={["primary", "primary", "danger", "primary"]}
                buttonTexts={[
                  "Continue",
                  "Loading...",
                  "Unable to verify your email. Try again?",
                  "Continued!",
                ]}
                buttonColor={["", "", "", ""]}
                callback={this.refresh}
              /> */}
            </Row>
          </Container>
        )
      } else {
        return (
          <Container className="topSpacing">
            <TitleLine title="Verify Email" description="Email Verified!" />

            <Row
              className="justify-content-center sidesPadding"
              style={{ marginTop: "100px" }}
            >
              <Button
                variant="success"
                onClick={() => navigate(this.props.navigate)}
              >
                Continue
              </Button>{" "}
            </Row>
          </Container>
        )
      }
    }
  }
}

export default VerifyEmail
