import React from "react"
import Container from "react-bootstrap/Container"
import Layout from "../components/utils/layout"
import VerifyEmail from "../components/onboarding/VerifyEmail"
import dots from "../images/bgs/dots.svg"


const VerifyEmailPage = () => (
  <Layout image={dots} full>
    <Container>
      <VerifyEmail />
    </Container>
  </Layout>
)

export default VerifyEmailPage
