import React from "react"
import { Button } from "antd"
import "antd/dist/antd.css" // or 'antd/dist/antd.less'

class NewAsyncButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      buttonText: this.props.buttonTexts[0],
      buttonVariant: this.props.buttonVariants[0],
      buttonColor: this.props.buttonColor[0],
    }
  }

  reset = (feedbackMessage, ind) => {
    let index = ind || this.props.buttonTexts.indexOf(feedbackMessage)

    this.setState({
      isLoading: false,
      buttonText: feedbackMessage,
      buttonVariant: this.props.buttonVariants[index],
      buttonColor: this.props.buttonColor[index],
    })
  }

  handleLoading = () => {
    this.setState(
      {
        isLoading: true,
        buttonText: this.props.buttonTexts[1],
        buttonVariant: this.props.buttonVariants[1],
        buttonColor: this.props.buttonColor[1],
      },
      () => {
        setTimeout(
          function() {
            this.props.callback(this.reset)
          }.bind(this),
          750
        )
      }
    )
  }

  render() {
    return (
      <>
        <Button
          type={this.state.buttonVariant}
          style={{ background: this.state.buttonColor }}
          loading={this.state.isLoading}
          onClick={this.handleLoading}
          size="large"
        >
          {this.state.buttonText}
        </Button>
      </>
    )
  }
}

export default NewAsyncButton
